<template>
  <div class="pt-12">
    <div class="heading-text">Set new password</div>
    <div class="subHeading-text my-5">
      Please enter a new password for {{ userEmail }}
    </div>
    <v-form ref="resetPasswordForm" lazy-validation>
      <v-row no-gutters class="">
        <v-col xs="8" class="">
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            label="Password"
            class="textField-LoginField"
            :rules="[rules.required]"
            v-model="resetPasswordForm.password"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @keyup.enter="submitResetPasswordForm"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="">
        <v-col xs="8" class="">
          <v-text-field
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            label="Confirm Password"
            class="textField-LoginField"
            :rules="[rules.required, matchPassword]"
            v-model="resetPasswordForm.confirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            @keyup.enter="submitResetPasswordForm"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        block
        dark
        class="login-btn py-7 rounded-lg"
        style=""
        color="#D30024"
        @click="submitResetPasswordForm"
        ref="submitBtn"
        :loading="resetPasswordForm.submitting"
      >
        <span class="text-loginBtn">Reset Password</span>
        <v-icon right size="30" black> mdi-chevron-right </v-icon>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import RULES from "@/common/fieldRules";
import { API_ADMIN_RESET_PASSWORD } from "@/constants/APIUrls";
import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import authToken from "@/common/authToken";
import { TOKEN, PASSWORD } from "@/constants/APIKeys";
import { mapActions } from "vuex";
import Helper from "@/helper";

export default {
  name: "ResetPasswordPage",
  components: {},
  data() {
    return {
      token: "",
      showPassword: false,
      showConfirmPassword: false,
      matchPassword: (v) =>
        v === this.resetPasswordForm.password || "Password does not match",
      rules: RULES,
      loading: false,
      userEmail: "",
      resetPasswordForm: {
        password: "",
        confirmPassword: "",
        submitted: false,
        submitting: false,
      },
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),
    /**
     * Form submit handler
     * @param e
     */
    submitResetPasswordForm() {
      console.log(this.$route.query.redirect_url + "parent");
      if (this.$refs.resetPasswordForm.validate()) {
        const self = this;
        self.resetPasswordForm.submitting = true;

        console.log("Form validated");
        /**
         * Success handler of API call
         */
        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          self.showToast({
            message: "Password has been reset successfully.",
            color: "s",
          });
          try {
            // Save token in local storage
            localStorage.setItem("token", res.headers.token);
          } catch (e) {
            console.log(e);
          }
          /**
           * Redirect to specific user panel
           */
          console.log(authToken.decodedToken(), "DECODE");
          let userTeam = authToken.decodedToken().user_team;
          self.showToast({
            message: "Password has been reset successfully",
            color: "s",
          });

          // Redirect to admin panel
          if (this.$route.query.redirect_url) {
            console.log(authToken.decodedToken(), "DECODE");

            let tokenQuery = `token=${res.headers.token}`;

            // Check if redirect_url already contains a query string
            let separator = this.$route.query.redirect_url.includes("?")
              ? "&"
              : "?";

            let finalUrl = `${this.$route.query.redirect_url}${separator}parent&${tokenQuery}`;

            window.open(finalUrl, "_self");
          } else {
            self.$router.push(Helper.getUserPanelUrl(userTeam));
          }

          self.resetPasswordForm.submitting = false;
        };
        /**
         * Failure handler of API call
         */
        const failureHandler = (res) => {
          console.log("Failure ", res);
          self.resetPasswordForm.submitting = false;
        };
        let formData = {};
        formData[PASSWORD] = this.resetPasswordForm[PASSWORD];
        let headers = {};
        headers[TOKEN] = this[TOKEN];

        /**
         * API Call for RESET PASSWORD
         */
        Axios.request_POST(
          API_ADMIN_RESET_PASSWORD,
          formData,
          headers,
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },
  },
  mounted() {
    if (this.$route.query.token) {
      if (
        authToken.isCorrectResetPasswordToken(this.$route.query.token) === false
      ) {
        // Redirect to Login if token is corrupt
        this.$router.push(ROUTER_URL.auth.children.login.link);
      } else {
        this.token = this.$route.query.token;
        this.userEmail = authToken.decodedResetPasswordTokenFromString(
          this.token
        ).user_email;
        var censorWord = function (str) {
          return str[0] + "*".repeat(str.length - 2) + str.slice(-5);
        };

        var censorEmail = function (email) {
          var arr = email.split("@");
          return censorWord(arr[0]) + "@" + censorWord(arr[1]);
        };

        this.userEmail = censorEmail(this.userEmail);
      }
    } else {
      console.log("NIKAL", this.$route.query);
      // Redirect to Login if no token in URL
      this.$router.push(ROUTER_URL.auth.children.login.link);
    }
  },
};
</script>

<style scoped>
.heading-text {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #2c2c2c;
}
.subHeading-text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
}
.textField-LoginField {
  z-index: 99;
}
.textField-LoginField >>> .v-input__slot {
  background-color: #f7f7f7 !important;
  /* border: 1px solid; */
  border-color: #c1bbbb;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.textField-LoginField >>> input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset;
}

.textField-LoginField >>> .v-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #afafaf;
}
.login-btn {
  z-index: 99;
}
.login-btn >>> .text-loginBtn {
  text-transform: none;
  letter-spacing: 0ch;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 161.5%;
  color: #ffffff;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .heading-text {
    font-size: 25px;
    line-height: 35px;
  }
  .subHeading-text {
    font-size: 18px;
    line-height: 20px;
  }
  .textField-LoginField >>> .v-label {
    font-size: 18px;
  }
  .login-btn >>> .text-loginBtn {
    font-size: 20px;
    line-height: 161.5%;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1904px) {
  .heading-text {
    font-size: 36px;
    line-height: 43px;
  }
  .subHeading-text {
    font-size: 22px;
    line-height: 26px;
  }
  .textField-LoginField >>> .v-label {
    font-size: 18px;
  }
  .login-btn >>> .text-loginBtn {
    font-size: 20px;
    line-height: 161.5%;
  }
}
</style>
